import { useContext, useState } from 'react';
import Pagination from 'react-bootstrap/Pagination';
import TableClienteMain from '../Tables/TableClientesMain';
import { ClienteContext } from '../../Context/ClienteContext';


const ComponentPagination = (props) => {
  const { currentPage, setCurrentPage } = useContext(ClienteContext)

  function alteraPagina1() {
    console.log('CONTEXT PORTARIA pagination = ', currentPage);
    setCurrentPage(0)
  }

  function alteraPagina2() {
    console.log('CONTEXT PORTARIA pagination = ', currentPage);
    setCurrentPage(1)
  }

  function alteraPagina3() {
    console.log('CONTEXT PORTARIA pagination = ', currentPage);
    setCurrentPage(2)
  }

  function alteraPagina4() {
    console.log('CONTEXT PORTARIA pagination = ', currentPage);
    setCurrentPage(3)
  }

  function alteraPagina5() {
    console.log('CONTEXT PORTARIA pagination = ', currentPage);
    setCurrentPage(4)
  }

  return (
    <>


      <Pagination>
        <p>Página : </p>

        <Pagination.Item onClick={alteraPagina1}>{1}</Pagination.Item>
        <Pagination.Item onClick={alteraPagina2}>{2}</Pagination.Item>
        <Pagination.Item onClick={alteraPagina3}>{3}</Pagination.Item>
        <Pagination.Item onClick={alteraPagina4}>{4}</Pagination.Item>
        <Pagination.Item onClick={alteraPagina5}>{5}</Pagination.Item>


      </Pagination>

    </>
  );
}

export default ComponentPagination;