import React, { useEffect, useState, useContext } from "react";
import Form from 'react-bootstrap/Form'
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import { useForm } from "react-hook-form";
import AsyncSelect from 'react-select/async';
import RequisicaoPost from "../../../../config/requisicoes/requisicaoPOST";
import Button from "react-bootstrap/esm/Button";
import styles from "./FormEditarServicoRampa.module.css";
import { useNavigate } from 'react-router-dom';
import { ClienteContext } from '../../../../Context/ClienteContext'


const FormEditarServicoRampa = (props) => {

    //const {dadosEmbarcacao, setDadosEmbarcacao } = useContext(EmbarcacaoContext)
    const { dadosCliente } = useContext(ClienteContext);

    const { handleSubmit, register, formState: { errors }, } = useForm();
    const [resultadoPesquisa, setResultadoPesquisa] = useState([]);
    const [proprietarioInfos, setProprietarioInfos] = useState({});
    const [embsSelecionadas, setEmbsSelecionadas] = useState([0]);
    const [embSelecionada, setEmbSelecionada] = useState({});
    const [modeloEmbSelecionado, setModeloEmbSelecionado] = useState("");
    const navigate = useNavigate();

    console.log('CLIENTE CONTEXT FORM EDITAR SERVICO = ', dadosCliente);
    var isReadOnly;

    if (props.typeForm === 'detalhar') {
        isReadOnly = [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,1];

    }  
    
    if (props.typeForm === 'editar') {
        isReadOnly = [0,0,0,0,0,0,0,0,1,1,1,0,0]
    }

    console.log(isReadOnly);

    async function pesquisaProprietarios(data) {
        const newData = { ...data, data }
        const tamanho = newData?.data?.toString().length;

        if (tamanho > 3) {
            const resultado = await RequisicaoPost('/listarProprietarios', newData)
            setResultadoPesquisa(resultado.rows);
            const proprietarios = resultado.rows;
            const proprietariosFormatado = proprietarios.map(item => ({ value: item?.id, label: item?.id + '-' + item?.nome }))
            return proprietariosFormatado;
        }
    }

    function listarEmbsDoCliente() {
        const clienteSelecionado = resultadoPesquisa.filter(item => item?.id === proprietarioInfos.value)
        setEmbsSelecionadas(clienteSelecionado[0]?.Embarcacoes);

    }

    useEffect(listarEmbsDoCliente, [proprietarioInfos, resultadoPesquisa]);

    function changeEmbSelect(newEmb) {
        const novoModelo = embsSelecionadas?.filter((item) => item.nome === newEmb)[0]?.modeloCascoEmb;
        const idEmbSelecionado = embsSelecionadas?.filter((item) => item.nome === newEmb)[0]?.id;
        setEmbSelecionada({ nome: newEmb, id: Number(idEmbSelecionado) });
        setModeloEmbSelecionado(novoModelo)
    }


    async function salvarDados(data) {
        const dadosAtualizados = { ...data, id: Number(dadosCliente?.id) };
        const salvar = await RequisicaoPost('/atualizaOSrampa', dadosAtualizados);

        if (salvar.status === "success") {
            navigate('/servicoRampa');
        } else {
            console.log('ERRO AO SALVAR OS');
        }

    }



    return (
        < div className={styles.formArea}>
            <Form onSubmit={handleSubmit(salvarDados)}>
                <section>


                    <h2>Ordem Serviço {dadosCliente?.id}</h2>

                    <Form.Group as={Row} className="mb-3">

                        <Form.Group as={Col} className="mb-3">
                            <Col xs={1}>
                                <Form.Label>Data Solicitação:</Form.Label>
                            </Col>

                            <Col xs={3}>
                                <Form.Control {...register("dataSolicitacao")} defaultValue={dadosCliente?.dataSolicitacao?.slice(0, 10)} disabled={isReadOnly[0]} type="date"></Form.Control>
                            </Col>
                        </Form.Group>

                        <Form.Group as={Col} className="mb-3">
                            <Col xs={2}>
                                <Form.Label>Hora Solicitação:</Form.Label>
                            </Col>

                            <Col xs={3}>
                                <Form.Control {...register("horaSolicitacao")} defaultValue={dadosCliente?.horaSolicitacao} disabled={isReadOnly[1]} type="time"></Form.Control>
                            </Col>
                        </Form.Group>
                    </Form.Group>

                    {dadosCliente?.statusAtendimento === 'Na Água' || dadosCliente?.statusAtendimento === 'Finalizado' ?

                        <Form.Group as={Row} className="mb-3">

                            <Form.Group as={Col} className="mb-3">
                                <Col xs={1}>
                                    <Form.Label>Data Descida:</Form.Label>
                                </Col>

                                <Col xs={3}>
                                    <Form.Control {...register("dataDescida")} defaultValue={dadosCliente?.dataDescida?.slice(0, 10)} disabled={isReadOnly[2]} type="date"></Form.Control>
                                </Col>
                            </Form.Group>

                            <Form.Group as={Col} className="mb-3">
                                <Col xs={2}>
                                    <Form.Label>Hora Descida:</Form.Label>
                                </Col>

                                <Col xs={3}>
                                    <Form.Control {...register("horaDescida")} defaultValue={dadosCliente?.horaDescida} disabled={isReadOnly[3]} type="time"></Form.Control>
                                </Col>
                            </Form.Group>
                        </Form.Group>

                        :
                        ""
                    }

                    {dadosCliente?.statusAtendimento === 'Finalizado' ?

                        <Form.Group as={Row} className="mb-3">

                            <Form.Group as={Col} className="mb-3">
                                <Col xs={1}>
                                    <Form.Label>Data Subida:</Form.Label>
                                </Col>

                                <Col xs={3}>
                                    <Form.Control {...register("dataSubida")} defaultValue={dadosCliente?.dataSubida.slice(0, 10)} disabled={isReadOnly[4]} type="date"></Form.Control>
                                </Col>
                            </Form.Group>

                            <Form.Group as={Col} className="mb-3">
                                <Col xs={2}>
                                    <Form.Label>Hora Subida:</Form.Label>
                                </Col>

                                <Col xs={3}>
                                    <Form.Control {...register("horaSubida")} defaultValue={dadosCliente?.horaSubida} disabled={isReadOnly[5]} type="time"></Form.Control>
                                </Col>
                            </Form.Group>
                        </Form.Group>

                        :
                        ""
                    }


                    <Form.Group as={Row} className="mb-3">
                        <Col xs={2}>
                            <Form.Label>Tipo Serviço</Form.Label>
                        </Col>
                        <Col xs={3}>
                            <Form.Select {...register("tipoServico")} defaultValue={dadosCliente?.tipoServico} disabled={isReadOnly[6]}>
                                <option key={1}>Abastecer & Descer</option>
                                <option key={2}>Abastecer & Levar</option>
                                <option key={3}>Descer</option>
                                <option key={4}>Manutenção</option>
                                <option key={5}>Levar</option>

                            </Form.Select>
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} className="mb-3">
                        <Col xs={2}>
                            <Form.Label>Status Atendimento:</Form.Label>
                        </Col>
                        <Col xs={3}>
                            <Form.Select {...register("statusAtendimento")} defaultValue={dadosCliente?.statusAtendimento} disabled={isReadOnly[7]}>
                                <option key={1}>Na Fila</option>
                                <option key={2}>Em Atendimento</option>
                                <option key={3}>Na Água</option>
                                <option key={4}>Finalizado</option>

                            </Form.Select>
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} className="mb-3">
                        <Col xs={2}>
                            <Form.Label>Cliente:</Form.Label>
                        </Col>
                        <Col xs={5}>
                            <Form.Control {...register("cliente")} placeholder={"Digite nome Cliente"} defaultValue={dadosCliente?.Cliente?.nome} disabled={isReadOnly[8]}></Form.Control>
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} className="mb-3">
                        <Col xs={2}>
                            <Form.Label>Nome Embarcação:</Form.Label>
                        </Col>
                        <Col xs={4}>
                            <Form.Select
                                {
                                ...register(
                                    "embarcacao",
                                    {
                                        //required: true,

                                        //validate: value => value !== "Selecione"
                                    },

                                )
                                }
                                onChange={(event) => changeEmbSelect(event.target.value)}
                                defaultValue={dadosCliente?.Embarcaco?.nome}
                                disabled={isReadOnly[9]}
                            >
                                <option>{dadosCliente?.Embarcaco?.nome || 'Selecione'}</option>
                                {embsSelecionadas?.map(item => (
                                    <option key={item.id}>{item.nome}</option>
                                ))}
                            </Form.Select>
                            {errors !== undefined && errors?.embarcacao?.type === 'validate' && <p className={styles.formError}>Selecione uma Embarcação</p>}

                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} className="mb-3">
                        <Col xs={2}>
                            <Form.Label>Modelo Emb:</Form.Label>
                        </Col>

                        <Col xs={4}>
                            <Form.Control {...register("modeloEmb")} defaultValue={dadosCliente?.Embarcaco?.modeloCascoEmb} disabled={isReadOnly[10]}></Form.Control>
                        </Col>
                    </Form.Group>


                    <Form.Group as={Row} className="mb-3">
                        <Col xs={2}>
                            <Form.Label>Combustível:</Form.Label>
                        </Col>

                        <Col xs={2}>
                            <Form.Control {...register("qtadeCombustivel")} defaultValue={dadosCliente?.qtadeCombustivel} disabled={isReadOnly[11]}></Form.Control>
                        </Col>

                    </Form.Group>

                    <Form.Group as={Row} className="mb-3">
                        <Col xs={2}>
                            <Form.Label>Observação:</Form.Label>
                        </Col>

                        <Col xs={5}>
                            <Form.Control {...register("observacao")} as="textarea" rows={4} defaultValue={dadosCliente?.observacao} disabled={isReadOnly[12]}></Form.Control>
                        </Col>

                    </Form.Group>

                    {props.typeForm === 'editar' ?
                        <Row>
                            <Button type="submit">Salvar</Button>
                        </Row>
                        :
                        ""
                    }
                </section>
            </Form>

        </div>
    )
}

export default FormEditarServicoRampa;