import React, { useContext, useState } from "react";
import Button from "react-bootstrap/esm/Button";
import Form from 'react-bootstrap/Form'
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import { useForm} from "react-hook-form";
import styles from "./FormEditarEmbarcacao.module.css";
//import RequisicaoPost from "../../../../config/requisicoes/requisicaoPOST";
import { useNavigate } from 'react-router-dom';
import { validaPlacaVeiculo } from "../../../../services/validation";
import { AuthContext } from "../../../../Context/AuthContext";
import AsyncSelect from 'react-select/async';
import requisicaoPost from '../../../../config/requisicoes/requisicaoPOST'
import Cookies from 'universal-cookie';

const FormEditarEmbarcacao = (props) => {
    const [modeChangeProprietario, setModeChangeProprietario] = useState(false);
    const [proprietarioInfos, setProprietarioInfos] = useState({ value: props.dados?.Cliente?.id });
    
    
    const cookies = new Cookies();
    const userInfo = cookies.get('userInfo');
    const userAlc = cookies.get('alc');

    console.log('PROPS DENTRO FORM EDITAR EMB  = ', props);



    const { handleSubmit, register, formState: { errors }, watch } = useForm();
    const navigate = useNavigate();

    var isReadOnly = [1];

    if (props.formType === "edit") {
        isReadOnly[0] = 0;
    }

    if (props.formType === "detalhar") {
        isReadOnly[0] = 1;
    }

    async function atualizaDados(data) {
        const novoProprietario = {clienteId : Number(proprietarioInfos?.value)}
        const atualizacao = await requisicaoPost('/atualizarEmbarcacao', { ...data, ...novoProprietario, id: Number(props?.dados?.id) });
        navigate('/embarcacoesMain')
    }

    async function pesquisaProprietarios(data) {
        const newData = { ...data, data }
        const tamanho = newData?.data?.toString().length;

        if (tamanho > 3) {
            const resultado = await requisicaoPost('/listarProprietarios', newData)
            const proprietarios = resultado.rows;
            const proprietariosFormatado = proprietarios.map(item => ({ value: item?.id, label: item?.id + `-` + item?.nome }))
            //proprietariosFormatado.push({ value: 0, label: " Adicionar Novo" })
            return proprietariosFormatado;
        }

    }

    return (

        <>
            < div className={styles.formArea}>

                <Form onSubmit={handleSubmit(atualizaDados)}>
                    <section>

                            {modeChangeProprietario !== true ?
                                <>
                                 <strong>Código Proprietário:</strong> {props?.dados?.Cliente?.id}<br />
                                 <strong>Proprietário:</strong> {props?.dados?.Cliente?.nome}
                                <Form.Group key="A" as={Row}>
                                   
                                    <Col>
                                        {props.formType === "detalhar" ? "" :
                                            <Button size="sm" variant="outline-secondary" onClick={() => { setModeChangeProprietario(true) }}>Alterar</Button>
                                        }
                                    </Col>
                                </Form.Group>
                                </>
                                :
                                ""
                            }
                        <Row className="mb-3">


                            {modeChangeProprietario === true ?
                                <Form.Group as={Row}>
                                    <Form.Label><strong>Novo Proprietário:</strong></Form.Label>
                                    <AsyncSelect placeholder={"Pesquisar Novo Proprietário"} cacheOptions loadOptions={pesquisaProprietarios} defaultOptions onChange={(data) => { setProprietarioInfos(data) }} >
                                    </AsyncSelect>
                                </Form.Group>
                                :
                                ""
                            }
                        </Row>

                        <hr />
                        <Form.Group >
                            <Row className="mb-3">
                                <Form.Group key="A" as={Row}>
                                    <Form.Label column lg={3}>
                                        <strong>Código Emb: {props?.dados?.id}</strong>
                                    </Form.Label>

                                </Form.Group>

                                <Form.Group key="B" as={Col} xs={6}>
                                    <Form.Group as={Row} >
                                        <Form.Label >
                                            Tipo:
                                        </Form.Label>
                                        <Col xs={7}>
                                            <Form.Select  {...register('tipo', { validate: value => value !== "Selecione", required: true })} defaultValue={props.dados?.tipo} readOnly={isReadOnly[0]} className={isReadOnly[0] === 1 ? styles.noEditable : ''}>
                                                <option>Selecione</option>
                                                <option>Jet-Ski</option>
                                                <option>Barco Pesca</option>
                                                <option>Lancha</option>
                                            </Form.Select>
                                            {errors !== undefined && errors?.tipo?.type === 'validate' && <p className={styles.formError}>Selecione tipo</p>}



                                        </Col>
                                    </Form.Group>
                                </Form.Group>

                                <Form.Group key="C" as={Col} xs={4}>
                                    <Form.Group as={Row} >
                                        <Form.Label sm={4}>
                                            Tamanho (Pés):
                                        </Form.Label>
                                        <Col xs={8}>
                                            <Form.Control  {...register('tamanho')} defaultValue={props.dados?.tamanho} readOnly={isReadOnly[0]} className={isReadOnly[0] === 1 ? styles.noEditable : ''} type="number"></Form.Control>
                                        </Col>
                                    </Form.Group>
                                </Form.Group>
                            </Row>

                            <Row className="mb-3" >
                                <Form.Group key="D" as={Col}>
                                    <Form.Group as={Row} >
                                        <Form.Label sm={1}>
                                            Data Entrada:
                                        </Form.Label>
                                        <Col xs={5}>
                                            <Form.Control  {...register('dataEntradaEmb', { required: true })} defaultValue={props.dados?.dataEntradaEmb?.slice(0, 10)} type="date" readOnly={isReadOnly[0]} className={isReadOnly[0] === 1 ? styles.noEditable : ''}></Form.Control>
                                        </Col>
                                    </Form.Group>
                                </Form.Group>

                                <Form.Group as={Col}>
                                    <Form.Group as={Row}>
                                        <Form.Label lg={5}>
                                            N° Inscrição:
                                        </Form.Label>
                                        <Col sm={10}>
                                            <Form.Control  {...register('NinscricaoEmb')} defaultValue={props.dados?.NinscricaoEmb} readOnly={isReadOnly[0]} className={isReadOnly[0] === 1 ? styles.noEditable : ''} />
                                        </Col>
                                    </Form.Group>
                                </Form.Group>
                            </Row>

                            <Row className="mb-3">

                                <Form.Group key="z" as={Col}>
                                    <Form.Group as={Row} >
                                        <Form.Label sm={1}>
                                            Status:
                                        </Form.Label>
                                        <Col xs={9}>
                                            <Form.Select  {...register('status', { required: true })} defaultValue={props.dados?.status} type="text" readOnly={isReadOnly[0]} className={isReadOnly[0] === 1 ? styles.noEditable : ''}>
                                                <option>Ativo</option>
                                                <option>Inativo</option>
                                            </Form.Select>
                                        </Col>
                                    </Form.Group>

                                    {watch("status") === "Inativo" || props.dados?.status === "Inativo" ?
                                        <div>
                                            <Form.Group as={Col}>
                                                <Form.Group as={Row} >
                                                    <Form.Label sm={1}>
                                                        Data Saída:
                                                    </Form.Label>
                                                    <Col xs={9}>
                                                        <Form.Control  {...register('dataSaida')} defaultValue={props.dados?.dataEntradaEmb?.slice(0, 10)} type="date" readOnly={isReadOnly[0]} className={isReadOnly[0] === 1 ? styles.noEditable : ''}></Form.Control>
                                                    </Col>
                                                </Form.Group>
                                            </Form.Group>
                                        </div>
                                        : ''


                                    }
                                </Form.Group>

                            </Row>

                            <Row className="mb-3" >
                                <Form.Group key="E" as={Col}>
                                    <Form.Group as={Row}>
                                        <Form.Label sm={2}>
                                            Nome:
                                        </Form.Label>
                                        <Col sm={8}>
                                            <Form.Control  {...register('nome')} defaultValue={props.dados?.nome} type="text" readOnly={isReadOnly[0]} className={isReadOnly[0] === 1 ? styles.noEditable : ''} />
                                        </Col>
                                    </Form.Group>
                                </Form.Group>

                                <Form.Group key="F" as={Col}>
                                    <Form.Group as={Row}>
                                        <Form.Label sm={2}>
                                            Placa Carreta:
                                        </Form.Label>
                                        <Col sm={9}>
                                            <Form.Control  {...register('placaCarreta', { validate: value => validaPlacaVeiculo(value) })} defaultValue={props.dados?.placaCarreta} readOnly={isReadOnly[0]} className={isReadOnly[0] === 1 ? styles.noEditable : ''} />
                                            {errors !== undefined && errors?.placaCarreta?.type === 'validate' && <p className={styles.formError}>Placa Inválida</p>}
                                        </Col>
                                    </Form.Group>
                                </Form.Group>
                            </Row>

                            <Row className="mb-3" >
                                <Form.Group as={Col}>
                                    <Form.Group as={Row}>
                                        <Form.Label lg={5}>
                                            Motor:
                                        </Form.Label>
                                        <Col sm={10}>
                                            <Form.Control  {...register('motorEmbarcacao')} defaultValue={props.dados?.motorEmbarcacao} readOnly={isReadOnly[0]} className={isReadOnly[0] === 1 ? styles.noEditable : ''} />
                                        </Col>
                                    </Form.Group>
                                </Form.Group>

                                <Form.Group as={Col}>
                                    <Form.Group as={Row}>
                                        <Form.Label lg={5}>
                                            Modelo Casco:
                                        </Form.Label>
                                        <Col sm={10}>
                                            <Form.Control  {...register('modeloCascoEmb',)} defaultValue={props.dados?.modeloCascoEmb} readOnly={isReadOnly[0]} className={isReadOnly[0] === 1 ? styles.noEditable : ''} />
                                        </Col>
                                    </Form.Group>
                                </Form.Group>
                            </Row>

                            <Row className="mb-3" >
                                <Form.Group as={Col}>
                                    <Form.Group as={Row}>
                                        <Form.Label lg={5}>
                                            Nº Série Motor:
                                        </Form.Label>
                                        <Col sm={10}>
                                            <Form.Control  {...register('NserieMotorEmb')} defaultValue={props.dados?.NserieMotorEmb} readOnly={isReadOnly[0]} className={isReadOnly[0] === 1 ? styles.noEditable : ''} />
                                        </Col>
                                    </Form.Group>
                                </Form.Group>

                                <Form.Group as={Col}>
                                    <Form.Group as={Row}>
                                        <Form.Label lg={5}>
                                            Nº Série Casco:
                                        </Form.Label>
                                        <Col sm={10}>
                                            <Form.Control  {...register('NserieCascoEmb')} defaultValue={props.dados?.NserieCascoEmb} readOnly={isReadOnly[0]} className={isReadOnly[0] === 1 ? styles.noEditable : ''} />
                                        </Col>
                                    </Form.Group>
                                </Form.Group>
                            </Row>

                            <Row className="mb-3" >
                            { userAlc > 2 ?
                                <Form.Group as={Col}>
                                    <Form.Group as={Row} className="mb-3">
                                        <Form.Label sm={2}>
                                            Valor Mensal:
                                        </Form.Label>
                                        <Col sm={8}>
                                            <Form.Control  {...register('valorMensalidade', { required: true })} defaultValue={props.dados?.valorMensalidade} placeholder="R$" readOnly={isReadOnly[0]} className={isReadOnly[0] === 1 ? styles.noEditable : ''} type="number"></Form.Control>
                                        </Col>
                                    </Form.Group>
                                </Form.Group>
                            :""}
                                <Form.Group as={Col}>
                                    <Form.Group as={Row} className="mb-3">
                                        <Form.Label sm={2}>
                                            Contrato:
                                        </Form.Label>
                                        <Col sm={8}>
                                            <Form.Select  {...register('tipoContrato', { required: true })} defaultValue={props.dados?.tipoContrato} placeholder="R$" readOnly={isReadOnly[0]} className={isReadOnly[0] === 1 ? styles.noEditable : ''} type="number">
                                                <option>Mensal</option>
                                                <option>Avulso</option>
                                            </Form.Select>
                                        </Col>
                                    </Form.Group>
                                </Form.Group>
                            </Row>

                            <hr />
                        </Form.Group>
                        {props.formType === "edit" ?
                            <Button type="submit">Salvar Alterações</Button>
                            :
                            ""
                        }
                    </section>
                </Form>
            </div>
        </>
    )

}

export default FormEditarEmbarcacao;