import {useState,useContext, useEffect}from "react";
import Header from "../../../../components/Header";
import Footer from "../../../../components/Footer";
import TableMovimentacoes from "../../../../components/Tables/TableMovimentacoes";
import { Button } from "react-bootstrap";
import { ClienteContext } from "../../../../Context/ClienteContext";
import RequisicaoGet from "../../../../config/requisicoes/requisicaoGET";
import Movimentacao from "../../../../shared/Types/Movimentacao";


const PageMovimentacoesMain = () => {
    const [dataTable, setDataTable] = useState<Movimentacao[]>([]);
    const { currentPage} = useContext(ClienteContext);
    

    async function getAllMovimentacoes(): Promise<Movimentacao[]> {
        const movimentacoes = await RequisicaoGet(`/listarMovimentacoes/?page=${currentPage}&size=50`);
        setDataTable(movimentacoes.movimentacoes.rows);
        console.log('mov = ',movimentacoes)
        return movimentacoes.movimentacoes.rows
    }

    useEffect(() => {getAllMovimentacoes()},[currentPage]);


    return(
        <>
            <Header/>
                <h1>Movimentações Garagem</h1>
                
                {/*<Button onClick={setInicial}>SetInicial</Button><br></br>*/}
                
                <Button href="/novaMovimentacao">Adicionar Movimentação</Button>
                <br/>
                <TableMovimentacoes
                    movimentacoes= {dataTable}
                    roteDetalhar='/detalharMovimentacao'
                    getAllMovimentacoes={getAllMovimentacoes}
                />
            <Footer/>
        </>
    )
}

export default PageMovimentacoesMain;